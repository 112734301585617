import ANGLAIS from "../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../assets/images/app/iconMatiers/SVT.png";

import belgiumFlag from "../assets/images/app/belgium-flag.png";
import frenchFlag from "../assets/images/app/french-flag.png";
import moroccoFlag from "../assets/images/app/morocco-flag.png";

export enum UserRoles {
  ADMIN = "admin",
  TEACHER = "teacher",
  STUDENT = "student",
  PARENT = "parent",
  ORGANISME = "organisme",
}

export enum AuthMethods {
  EMAIL = "email",
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

export enum FLAGS {
  GROUP_FLAG = "GROUP_FLAG",
  SINGLE_FLAG = "SINGLE_FLAG",
}

export enum StatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum HandlerInputError {
  title = "Le champ titre est requis",
  subtitles = "Le champ sous titre est requis",
  section = "Le champ section est requis",
  content = "Le champ contenu est requis",
  default = "Un champ n'a pas ete renseigner",
}

export const enum CHOICE {
  SAVE_AS_DRAFT = "SAVE_AS_DRAFT",
  SAVE_PERMANENTLY = "SAVE_PERMANENTLY",
}
export enum Language {
  ENGLISH = "en",
  FRENCH = "fr",
}
export enum educSystem {
  BELGE = "Système Belge",
  FRENCH = "Système Français",
  MOROCCO = "Système Marocain",
}

export enum PlayLevel {
  EASY = "Facile",
  MEDIUM = "Moyen",
  HARD = "Difficile",
}

export enum TypeExercices {
  ApplicationsDeCours = "Applications de cours",
  Classiques = "Classiques",
  Annales = "Annales",
  Entraînement = "Entraînement",
}

export enum StatusEntity {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}
export const converterMoneyCommission = (montant: number) => {
  if (montant <= 1000) return montant - montant * 0.3;
  else {
    if (montant <= 5000) return montant - montant * 0.22;
    else {
      if (montant <= 15000) return montant - montant * 0.15;
      return montant - montant * 0.5;
    }
  }
};

export enum TypeMatiers {
  mathematique = "Mathematiques",
  PhysiqueChimie = "Physique-chimie",
  SVT = "SVT",
  Francais = "Francais",
  Anglais = "Anglais",
  HistoireGeo = "Histoire-geo",
  Philosophie = "Philosophie",
}

export enum TypeCours {
  mathematique = "Mathématiques",
  PhysiqueChimie = "Physique-chimie",
  SVT = "SVT",
  Francais = "Français",
  Anglais = "Anglais",
  HistoireGeo = "Histoire-géo",
  Philosophie = "Philosophie",
}

export const StepTitle = (current: number) => {
  if (current === 1) {
    return "À PROPOS";
  }
  else if (current === 2) {
    return "PHOTO DE PROFIL";
  }
  else if (current === 3) {
    return "FORMATION";
  }
  else if (current === 4) {
    return "CERTIFICATION D’ENSEIGNEMENT";
  }
  else if (current === 5) {
    return "DESCRIPTION DU PROFIL";
  }
  else if (current === 6) {
    return "VIDÉO DE PRÉSENTATION";
  }
  else if (current === 8) {
    return "PROGRAMME ET DISPONIBILITÉ";
  }
}

export const StepMessage = (current: number) => {
  if (current === 1) {
    return "Commence à créer ton profil public en tant que professeur ici! Tes modifications seront automatiquement enregistrées à mesure que tu complètes chaque section. Tu pourras y revenir à tout moment afin de finaliser ton inscription.";
  }
  else if (current === 2) {
    return "";
  }
  else if (current === 3) {
    return "Tu as obtenu des certifications ou des diplômes dans le domaine de l'enseignement ? Si oui, décris-les pour gagner en crédibilité et attirer plus d'élèves!";
  }
  else if (current === 4) {
    return "Dis-en plus aux élèves sur les diplômes de l'enseignement supérieur que tu as obtenus ou que tu prépares actuellement.";
  }
  else if (current === 5) {
    return "Crée ou mets à jour ton titre et une nouvelle description de ton profil. Ces éléments apparaîtront sur ton profil dans la page 'Trouver un professeur'.";
  }
  else if (current === 6) {
    return "";
  }
}

export enum StaticLevels {
  staticLeveId = "62e00770bbf0e80cf966552d",
}

export const Languages = (shortcode: string) => {
  if (shortcode === 'en') {
    return "Anglais";
  }
  if (shortcode === 'fr') {
    return "Français";
  }
}

export const currentStep = (step: string) => {
  if (step === "a_propos") {
    return 2;
  }
  else if (step === "photo") {
    return 3;
  }
  else if (step === "certificate") {
    return 4;
  }
  else if (step === "diploma") {
    return 5;
  }
  else if (step === "description") {
    return 6;
  }
  else if (step === "video") {
    return 7;
  }
  else if (step === "cost") {
    return 8;
  }
  else {
    return 1;
  }
}

export const setIcon = (course: string) => {
  if (course === "Mathématiques") {
    return MATH;
  }
  else if (course === "Physique-chimie") {
    return PHYSIQUE;
  }
  else if (course === "SVT") {
    return SVT;
  }
  else if (course === "Français") {
    return FRANCAIS;
  }
  else if (course === "Anglais") {
    return ANGLAIS;
  }
  else if (course === "Histoire géo") {
    return HISTOIREGEO;
  }
  else if (course === "Philosophie") {
    return PHILO;
  }
}

export const setCourseName = (course: string) => {
  if (course === "Mathématiques") {
    return "Maths";
  }
  else if (course === "Physique-chimie") {
    return "Physique\nChimie";
  }
  else if (course === "SVT") {
    return SVT;
  }
  else if (course === "Français") {
    return "Français";
  }
  else if (course === "Anglais") {
    return "Anglais";
  }
  else if (course === "Histoire géo") {
    return "Histoire\nGeographie";
  }
  else if (course === "Philosophie") {
    return "Philo";
  }
}

export const setCountryFlag = (system: string) => {
  if (system === "Système Belge") {
    return belgiumFlag;
  }
  else if (system === "Système Français") {
    return frenchFlag;
  }
  else if (system === "Système Marocain") {
    return moroccoFlag;
  }
}

export const translateDayOfWeek = (day: string) => {
  if (day === "Monday") {
    return "Lundi";
  }
  if (day === "Tuesday") {
    return "Mardi";
  }
  if (day === "Wednestday") {
    return "Mercredi";
  }
  if (day === "Thursday") {
    return "Jeudi";
  }
  if (day === "Friday") {
    return "Vendredi";
  }
  if (day === "Saturday") {
    return "Samedi";
  }
  if (day === "Sunday") {
    return "Dimanche";
  }
} 