import { Col, DatePicker, Drawer, Form, Row, Select, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";

import moment from "moment";

//!STYLE
import { AppNotification } from "components/appNotification";
import AppButton from "components/libs/button";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import {
  displayDrawerAdsSpecific,
  setCurrentCreneau,
} from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import {
  useCreateAdSpecificMutation,
  useGetAllAdSpecificQuery,
  useUpCreSpecMutation,
} from "store/services/teacher/ads.services";
import {
  filterHoursByCurrentTime,
  handleClearNiveauMatUser,
  hours,
  hoursMinutes,
  hoursOnly,
} from "utils/formats";
import s from "../../TeacherAds/components/style.module.css";

const { Option } = Select;

const DrawerAdsCreneauSpecific: FC = () => {
  //!HOOKS
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetAllAdSpecificQuery(user?._id);
  const [createAdSpecific, { isLoading, isError, isSuccess }] =
    useCreateAdSpecificMutation();

  const [
    upCreSpec,
    { isLoading: isLoadingUp, isSuccess: isSuccessUp, isError: isErrorUp },
  ] = useUpCreSpecMutation();

  const { drawerAdsSpecific, currentCreneau } = useSelector(
    (state) => state.drawerAds
  );

  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [date, setDate] = useState<string>("");

  const [selectedHour, setSelectedHour] = useState<any>("");
  const [isThirty, setIsThirty] = useState<boolean>(false);

  // Handle input change
  const handleChange = (value: any) => {
    console.log(value);
    setSelectedHour(value);
  };

  function estIdMongoDB(chaine: string): boolean {
    const pattern = /^[0-9a-fA-F]{24}$/;
    return pattern.test(chaine);
  }

  const onSelectLevel = useCallback(async (levelId: string) => {
    return user?.nivMat
      .filter((nivMat: any) => {
        return nivMat?.niveau?._id === levelId;
      })
      .forEach((matiere: any) => {
        return setTempTopic(matiere?.matieres);
      });
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      if (Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])) {
        AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
        throw new Error("Invalid creneau");
      }
      const today = new Date();
      const isoDate = today.toISOString();
      const checkValueTopic = estIdMongoDB(values.matiere)
        ? values.matiere
        : currentCreneau?.topic[0]?._id;

      const finalData = {
        teacherId: user?._id,
        niches: {
          topic: [checkValueTopic],
          level: [values.levelIds],
          date: values.date._d,
          timeRanges: [
            {
              range: [
                {
                  hour: values?.one,
                },
                {
                  hour: values?.two,
                },
              ],
              type: values.type,
            },
          ],
        },
      };
      createAdSpecific(finalData);
    },
    [currentCreneau, upCreSpec, user]
  );

  useEffect(() => {
    if (isSuccess) {
      message.success("Créneau ajouté avec succès");
      dispatch(displayDrawerAdsSpecific(false));
      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
      }, 3000);
      form.resetFields();
    }
    if (isError) {
      message.error(badRequest.error);
    }
  }, [isSuccess, isLoading, isError]);

  // useEffect(() => {
  //   if (isSuccessUp) {
  //     message.success("Créneau modifié avec succès");
  //     dispatch(displayDrawerAdsSpecific(false));
  //     form.resetFields();
  //   }
  //   if (isErrorUp) {
  //     message.error("badRequest.error");
  //   }
  // }, [isLoadingUp, isSuccessUp, isErrorUp]);

  useEffect(() => {
    console.log(currentCreneau);
    if (currentCreneau?.date) {
      form.setFieldsValue({
        date: moment(currentCreneau?.day) || "",
        one: currentCreneau?.timeRanges[0] || "",
        two: currentCreneau?.timeRanges[1] || "",
        levelIds: currentCreneau?.level?._id || "",
        matiere: currentCreneau?.topic?.title || "",
        type: currentCreneau?.creneauTypes || "",
      });
    } else {
      form.resetFields();
    }
  }, [currentCreneau]);

  /* useEffect(() => {
    console.log(currentCreneau);
    if (currentCreneau?.date) {
      form.setFieldsValue({
        date: moment(currentCreneau?.date) || "",
        one: currentCreneau?.timeRanges[0]?.range[0]?.hour || currentCreneau?.timeRanges[0] || "",
        two:
          currentCreneau?.timeRanges[0]?.range[
            currentCreneau?.timeRanges[0]?.range.length - 1
          ]?.hour || currentCreneau?.timeRanges[1] || "",
        levelIds: currentCreneau?.level[0]?._id || currentCreneau?.level?._id || "",
        matiere: currentCreneau?.topic[0]?.title || currentCreneau?.topic?.title || "",
        type: currentCreneau?.timeRanges[0]?.type || currentCreneau?.creneauTypes || "",
      });
    } else {
      form.resetFields();
    }
  }, [currentCreneau]); */

  useEffect(() => {
    if (selectedHour.toString().includes("30")) {
      setIsThirty(true);
    }
    else {
      setIsThirty(false);
    }
  }, [selectedHour]);

  return (
    <Drawer
      title={
        <div className={s.__drawer__header}>
          {currentCreneau?.date ? <h3>Replanifier un créneau</h3> : <h3>Ajouter un créneau</h3>}
        </div>
      }
      headerStyle={{ border: "none" }}
      placement="right"
      width={"50%"}
      onClose={() => {
        dispatch(displayDrawerAdsSpecific(false));
        dispatch(setCurrentCreneau({}));
      }}
      visible={drawerAdsSpecific}
    >
      <Form onFinish={onFinish} form={form}>
        <Row
          justify="center"
          gutter={16}
          align="middle"
          style={{ marginBottom: "1.5em" }}
        >
          <Col>
            <h3 className={s.horaire}>Date</h3>
          </Col>
          <Col>
            {" "}
            <div className={s.time__picker}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"date"}
              >
                <DatePicker
                  bordered={false}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(1, "month") <= current
                    );
                  }}
                  style={{ width: "280px", fontSize: "10px" }}
                  onChange={(_, date: string) => setDate(date)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          gutter={16}
          style={{ marginBottom: "2em" }}
        >
          <Col>
            <h3 className={s.horaire}>Horaire</h3>
          </Col>
          <Col>
            <div className={s.time__picker}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"one"}
              >
                <Select
                  placeholder="Heures"
                  style={{ width: 120 }}
                  bordered={false}
                  onChange={handleChange}
                  options={filterHoursByCurrentTime(hours, date).map((hour) => {
                    return {
                      value: hour.value,
                      label: hour.label,
                    };
                  })}
                />
              </Form.Item>
            </div>
          </Col>
          <Col>
            <h3 style={{ color: "var(--first-color)" }}>à</h3>
          </Col>
          <Col>
            <div className={s.time__picker}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                name={["two"]}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
              >
                {isThirty ? <Select
                  placeholder="Heures"
                  style={{ width: 120 }}
                  bordered={false}
                  options={filterHoursByCurrentTime(hoursMinutes, date).map((hour) => {
                    return {
                      value: hour.value,
                      label: hour.label,
                    };
                  })}
                /> : <Select
                  placeholder="Heures"
                  style={{ width: 120 }}
                  bordered={false}
                  options={filterHoursByCurrentTime(hoursOnly, date).map((hour) => {
                    return {
                      value: hour.value,
                      label: hour.label,
                    };
                  })}
                />}
              </Form.Item>
            </div>
          </Col>
        </Row>

        <div className={s.full__content}>
          <div className={s.field__content}>
            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"levelIds"}
              >
                <Select
                  placeholder="Choisir un niveau"
                  style={{ width: "100%" }}
                  className={s.select}
                  bordered={false}
                  onChange={(value: string, fullValues: object) => {
                    onSelectLevel(value);
                  }}
                  options={handleClearNiveauMatUser(user?.nivMat)?.map(
                    (item: any) => {
                      return {
                        label: item?.niveau?.levelGroup,
                        options: [
                          {
                            label: item?.niveau?.title,
                            value: item?.niveau?._id,
                          },
                        ],
                      };
                    }
                  )}
                />
              </Form.Item>
            </div>

            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"matiere"}
              >
                <Select
                  className={s.select}
                  placeholder="Choisir une matière"
                  style={{ width: "100%" }}
                  bordered={false}
                  allowClear
                >
                  {tempTopic?.map((topic: any) => (
                    <Option
                      key={topic?.matiere?._id}
                      value={topic?.matiere?._id}
                    >
                      {topic?.matiere?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"type"}
              >
                <Select
                  className={s.select}
                  placeholder="Choisir un type de créneau"
                  style={{ width: "100%" }}
                  bordered={false}
                  allowClear
                >
                  <Option value={"individuel"}>Individuel</Option>

                  <Option value={"collectif"}>Collectif</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>

        <Row justify="center">
          <Form.Item style={{ margin: 0, padding: 0 }}>
            <AppButton loading={isLoading || isLoadingUp} htmlTypeSubmit={true}>
              Valider
            </AppButton>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default DrawerAdsCreneauSpecific;
