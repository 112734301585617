import { Drawer, Image, Skeleton } from 'antd';
import * as appRoutes from "config/routes.config";
import React, { Fragment, useState } from 'react';

import AppResult from 'components/appResults';
import s from "./style.module.css";

//IMAGES
import { UserRoles, setIcon } from 'common/enums';
import StudentNavBar from 'components/StudentNavBar';
import { SponsorDrawerPaiement } from 'components/paiementSponsor';
import { useNavigate } from 'react-router-dom';
import ParentStudentCalendar from 'screens/student/studentCalendar';

import Ads from "assets/images/app/header/ads.svg";
import Calendar from "assets/images/app/header/calendar.svg";
import Performance from "assets/images/app/header/performance.svg";
import Wallet from "assets/images/app/header/wallet.svg";
import SendUpdatePasswordRequestDrawer from 'components/updatePasswordDrawer/sendUpdateLink';
import Access from "../../assets/images/app/access.svg";
import Check from "../../assets/images/app/check.svg";
import Star from "../../assets/images/app/start.svg";

type Props = {
    userData: any;
    historyData: any;
    studentId: any;
    loading: boolean;
    user: any;
    sponsorData: any;
}

const ParentOrganismeStudentHistory: React.FC<Props> = ({ userData, historyData, studentId, loading, user, sponsorData }) => {

    const navigate = useNavigate();
    const isParent = user?.roles?.includes(UserRoles.PARENT);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [calendarDrawerVisibility, setCalendarDrawerVisibility] = useState<boolean>(false);
    const closeCalendarDrawer = async () => {
        setCalendarDrawerVisibility(false);
    }

    const [walletDrawerVisibility, setWalletDrawerVisibility] = useState<boolean>(false);
    const closeWalletDrawer = async () => {
        setWalletDrawerVisibility(false);
    }

    const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
    const onClosePasswordDrawer = () => {
        setPasswordDrawerVisible(false);
    };

    const MENU = [
        {
            id: 1,
            name: "Accès",
            icon: Access,
            iconSize: 25,
            link: "",
        },
        {
            id: 2,
            name: "Historiques",
            icon: Ads,
            iconSize: 35,
            link: `${appRoutes.STUDENT_HISTORY}/${studentId}`,
        },
        {
            id: 3,
            name: "Performance",
            icon: Performance,
            iconSize: 35,
            link: {
                pathname: `${appRoutes.STUDENT_STATISTIQUES}/${studentId}`,
                state: sponsorData,
            },
        },
        {
            id: 4,
            name: "Portefeuille",
            icon: Wallet,
            iconSize: 35,
            link: "",
        },
        {
            id: 5,
            name: "Calendrier",
            icon: Calendar,
            iconSize: 35,
            link: "",
        },
    ];

    const doAction = async (name: string) => {
        console.log(name);
        if (name === "Accès") {
            setPasswordDrawerVisible(true)
        }
        else if (name === "Calendrier") {
            setCalendarDrawerVisibility(true)
        }
        else if (name === "Portefeuille") {
            setWalletDrawerVisibility(true)
        }
        else if (name === "Performance") {
            isParent ? navigate(`${appRoutes.STUDENT_STATISTIQUES}/${studentId}`, { state: sponsorData }) : navigate(`${appRoutes.ORGANISM_STUDENT_STATISTIQUES}/${studentId}`, { state: sponsorData });
        }
        else { }
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "0px", position: "relative", top: "-30px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: "100px" }}>
                    {loading ? <Skeleton active /> : <StudentNavBar userData={userData} handleOpenPasswordDrawer={() => setPasswordDrawerVisible(true)} />}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {MENU.map((item) => (
                            <ul style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", fontSize: "1.2em", fontWeight: "bolder", marginLeft: "10px" }}>
                                <li>
                                    <Image
                                        preview={false}
                                        src={item.icon}
                                        width={item?.iconSize || 29}
                                        onClick={() => doAction(item?.name)}
                                        style={{ cursor: "pointer" }}
                                    />
                                </li>
                                <li>
                                    {" "}
                                    <span style={{ fontWeight: item.name === "Historiques" ? "500" : "normal", color: "#7FB1B2", cursor: "pointer" }} onClick={() => doAction(item?.name)}>{item.name}</span>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div className={s.lineBox}>
                    {loading ? <Skeleton active /> : <div
                        style={{
                            width: "100%",
                            borderRadius: "10px",
                            background: "#fafafa",
                            marginTop: "20px"
                        }}
                    >
                        {historyData.length > 0 ? (
                            <Fragment>
                                {historyData.map((historique: any, index: number) => (
                                    <div key={index} style={{ marginBottom: "2em" }} className={s.card}>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.2em" }}>
                                                <div><span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours terminé </span>le {historique['date']}</div>
                                                <div style={{ display: "flex", flexDirection: "row", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", background: "#D5D5D5", marginLeft: "20px", borderRadius: "8px" }}>
                                                    <img src={setIcon(historique['topic'])} height={30} /><span style={{ position: "relative", top: "2px" }}>{historique['topic']}</span>
                                                </div>
                                            </div>
                                            {historique['noted'] && <div className={s.rateBox}>
                                                <div className={s.dashedBox}>
                                                    <span style={{ color: "#7FB1B2" }}>Cours</span>
                                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateCours']}</span>
                                                </div>
                                                <div className={s.dashedBox}>
                                                    <span style={{ color: "#7FB1B2" }}>Application</span>
                                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateApplication']}</span>
                                                </div>
                                                <div className={s.dashedBox}>
                                                    <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateExercice']}</span>
                                                </div>
                                            </div>}
                                        </div>

                                        {historique['comment'] !== "" && <div style={{ fontSize: "1.2em", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                            {historique['comment']}
                                        </div>}
                                    </div>
                                ))}
                            </Fragment>
                        ) : (
                            <AppResult status={"info"} title="Aucune réservation" />
                        )}
                    </div>}
                </div>
            </div>
            <Drawer
                placement="right"
                closable={true}
                onClose={closeCalendarDrawer}
                visible={calendarDrawerVisibility}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <ParentStudentCalendar user={userData} />
                </div>
            </Drawer>
            <Drawer
                placement="right"
                closable={true}
                onClose={closeWalletDrawer}
                visible={walletDrawerVisibility}
                width={isMobile ? "100%" : "40%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <SponsorDrawerPaiement currentUser={userData} type="student" />
                </div>
            </Drawer>
            <SendUpdatePasswordRequestDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} userData={userData} />
        </>
    );
};

export default ParentOrganismeStudentHistory;