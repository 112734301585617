//!SCREENS
import AuthGuard from "components/auth/auth.guard";
import StudentRoute from "components/auth/auth.student.route";
import TeacherRoute from "components/auth/auth.teacher.route";
import * as appRoutes from "config/routes.config";
import { AnimatePresence } from "framer-motion";
import { FC, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Forbidden from "screens/app/401";
import NotFound from "screens/app/404/404";
import HomePage from "screens/app/landing";
import OrganismePage from "screens/organisme";
import ParentPage from "screens/parent";
import ProfesseurPage from "screens/teacher";

import Privacy from "screens/app/privacy";
import HomeLogin from "screens/app/start";
import EasyQuiz from "screens/student/ListesQuiz";
import RoomStudent from "screens/student/RoomStudent";
import StudentCalendar from "screens/student/calendar";
import Chapitre from "screens/student/chapitre";
import StudentCours from "screens/student/cours";
import {
  default as StudentCoursQuiz,
  default as StudentQuiz
} from "screens/student/coursQuiz";
import StudentDashboard from "screens/student/dashboard";
import StudentExercice from "screens/student/exercice";
import Level from "screens/student/level";
import MyClasses from "screens/student/myClasses";
import EchecAchat from "screens/student/paiement/Failing";
import SuccessAchat from "screens/student/paiement/Success";
import RegisterStudent from "screens/student/register";
import RegisterStudentByMail from "screens/student/registerByGmail";
import SearchTeacher from "screens/student/searchTeacher";
import Stats from "screens/student/stats";
import StudentChallengeQuiz from "screens/student/studentChallengeQuizz";
import StudentExercices from "screens/student/studentExos";
import Calendar from "screens/teacher/Calendar";
import Contact from "screens/teacher/Contact";
import Course from "screens/teacher/Course";
import CourseDuplicate from "screens/teacher/Course/cours.duplicate";
import EditCourse from "screens/teacher/Course/edit";
import EditChapter from "screens/teacher/Course/edit.chapter";
import EditCourseUpdated from "screens/teacher/Course/edit.chapter.updated";
import EditPropositionCourse from "screens/teacher/Course/proposition";
import Courses from "screens/teacher/Courses";
import Exercises from "screens/teacher/Exercices";
import FAQ from "screens/teacher/Faq";
import Quizz from "screens/teacher/Quizz";
import EditQuizz from "screens/teacher/Quizz/edit";
import EditQuizzProposition from "screens/teacher/Quizz/edit.proposition";
import Room from "screens/teacher/Room";
import Rooms from "screens/teacher/Rooms";
import SingleQuizz from "screens/teacher/SingleQuizz";
import PropositionsQuizz from "screens/teacher/SingleQuizz/propositions";
import QuizzDuplicate from "screens/teacher/SingleQuizz/quiz.duplicate";
import TeacherAds from "screens/teacher/TeacherAds";
import TeacherGetExercice from "screens/teacher/TeacherGetExercice";
import TeacherActivities from "screens/teacher/activites";
import Calendrier from "screens/teacher/calendrier";
import TeacherFindChapter from "screens/teacher/chapter";
import GetAllChapters from "screens/teacher/chapters";
import CoursesByLevel from "screens/teacher/coursesByLevel";
import TeacherCreateCours from "screens/teacher/createCours";
import CreateExercice from "screens/teacher/createExercice";
import TeacherCreateQuiz from "screens/teacher/createQuiz";
import TeacherDashboard from "screens/teacher/dashboard";
import Exercise from "screens/teacher/exercise";
import ExerciceDuplicate from "screens/teacher/exercise/exercice.duplicate";
import PropositionExercice from "screens/teacher/exercise/proposition";
import FindExoForChapter from "screens/teacher/findExoForChapter";
import FindQuizForChapter from "screens/teacher/findQuizForChapter";
import LoginTeacher from "screens/teacher/login";
import ProfileComplete from "screens/teacher/profileComplete";
import ProfileTeacher from "screens/teacher/profileTeacher";
import RegisterTeacher from "screens/teacher/register";
import RegisterByEmail from "screens/teacher/registerByEmail";
import TeacherStudentHistory from "screens/teacher/studentHistory";
import TeacherStudents from "screens/teacher/students";

///Parent & Organisme
import OrganismeRoute from "components/auth/auth.organisme.route";
import ParentRoute from "components/auth/auth.parent.route";

import OrganismeDashboard from "screens/organisme/dashboard";
import LoginOrganisme from "screens/organisme/login";
import RegisterOrganisme from "screens/organisme/register";
import OrganismeStudentHistory from "screens/organisme/studentHistory";
import OrganismeStudentStatisques from "screens/organisme/studentStats";
import OrganismeFindStudentTeacher from "screens/organisme/teachers";
import OrganismeWallets from "screens/organisme/wallet";

import ParentDashboard from "screens/parent/dashboard";
import LoginParent from "screens/parent/login";
import RegisterParent from "screens/parent/register";
import StudentHistory from "screens/parent/studentHistory";
import StudentStatisques from "screens/parent/studentStats";
import FindStudentTeacher from "screens/parent/teachers";
import Wallets from "screens/parent/wallet";

import ParentEchecAchat from "screens/parent/paiement/failing";
import ParentSuccessAchat from "screens/parent/paiement/success";

import OrganismeEchecAchat from "screens/organisme/paiement/failing";
import OrganismeSuccessAchat from "screens/organisme/paiement/success";

//STUDENT
// import RegisterStudentByMail from "screens/student/registerByGmail";
// import TeacherCreateCours from "screens/teacher/createCours";
// import TeacherCreateQuiz from "screens/teacher/createQuiz";
// import CreateExercice from "screens/teacher/createExercice";

const AppRoutes: FC = () => {
  //!HOOKS
  const location = useLocation();

  //!EFFECTS
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  //!RETURN
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path={appRoutes.HOME} element={<HomePage />} />
        <Route path={appRoutes.PROFESSEUR} element={<ProfesseurPage />} />
        <Route path={appRoutes.PARENT} element={<ParentPage />} />
        <Route path={appRoutes.ORGANISME} element={<OrganismePage />} />
        <Route path={appRoutes.LOGINS} element={<HomeLogin />} />

        <Route
          path={appRoutes.REGISTER_STUDENT}
          element={<RegisterStudent />}
        />
        <Route
          path={appRoutes.REGISTER_TEACHER}
          element={<RegisterTeacher />}
        />
        <Route
          path={appRoutes.REGISTER_TEACHER_BY_EMAIL}
          element={<RegisterByEmail />}
        />
        <Route
          path={appRoutes.COMPLETE_PROFILE}
          element={<ProfileComplete />}
        />

        <Route
          path={appRoutes.STUDENT_MY_CONTENT}
          element={<StudentDashboard />}
        />

        <Route
          path={appRoutes.STUDENT_DASHBOARD}
          element={<StudentRoute children={<Stats />} />}
        />

        <Route
          path={appRoutes.TEACHER_DASHBOARD}
          element={<TeacherRoute children={<TeacherDashboard />} />}
        />
        <Route
          path={appRoutes.TEACHER_ADS}
          element={<TeacherRoute children={<TeacherAds />} />}
        />
        <Route
          path={appRoutes.TEACHER_CALENDAR}
          element={<TeacherRoute children={<Calendar />} />}
        />

        <Route
          path={appRoutes.TEACHER_SET_CALENDAR}
          element={<TeacherRoute children={<Calendrier />} />}
        />

        <Route
          path={appRoutes.TEACHER_ROOMS}
          element={<TeacherRoute children={<Rooms />} />}
        />

        <Route
          path={appRoutes.TEACHER_COURSES}
          element={<TeacherRoute children={<Courses />} />}
        />
        <Route
          path={appRoutes.TEACHER_ACTIVITIES}
          element={<AuthGuard children={<TeacherActivities />} />}
        />
        <Route
          path={appRoutes.TEACHER_COURSES + "/:id"}
          element={<TeacherRoute children={<Course />} />}
        />
        <Route
          path={appRoutes.TEACHER_COURSES + "/:id/edit"}
          element={<TeacherRoute children={<EditCourse />} />}
        />
        <Route
          path={appRoutes.TEACHER_COURSES + "/:id/editCourse"}
          element={<TeacherRoute children={<EditCourseUpdated />} />}
        />

        <Route
          path={appRoutes.TEACHER_COURSES + "/:id/duplicate"}
          element={<TeacherRoute children={<CourseDuplicate />} />}
        />

        <Route
          path={appRoutes.TEACHER_PROPOSITION_COURS + "/:id/proposition"}
          element={<TeacherRoute children={<EditPropositionCourse />} />}
        />

        <Route
          path={appRoutes.TEACHER_PROPOSITION_EXO + "/:id/proposition"}
          element={<TeacherRoute children={<PropositionExercice />} />}
        />

        <Route
          path={appRoutes.TEACHER_COURSES + "/:id/edit/chapters/:chapterId"}
          element={<TeacherRoute children={<EditChapter />} />}
        />

        <Route
          path={appRoutes.TEACHER_EXERCICES}
          element={<TeacherRoute children={<Exercises />} />}
        />
        <Route
          path={appRoutes.TEACHER_EXERCICES + "/:id"}
          element={<TeacherRoute children={<Exercise />} />}
        />
        <Route
          path={appRoutes.TEACHER_GET_EXO + "/:id/duplicate"}
          element={<TeacherRoute children={<ExerciceDuplicate />} />}
        />
        <Route
          path={appRoutes.TEACHER_GET_EXO + "/:id"}
          element={<TeacherRoute children={<TeacherGetExercice />} />}
        />

        <Route
          path={appRoutes.TEACHER_QUIZZ}
          element={<TeacherRoute children={<Quizz />} />}
        />
        <Route
          path={appRoutes.TEACHER_QUIZZ + "/:id"}
          element={<TeacherRoute children={<SingleQuizz />} />}
        />
        <Route
          path={appRoutes.TEACHER_QUIZZ + "/:id/duplicate"}
          element={<TeacherRoute children={<QuizzDuplicate />} />}
        />
        <Route
          path={appRoutes.TEACHER_QUIZZ + "/:id/edit"}
          element={<TeacherRoute children={<EditQuizz />} />}
        />
        <Route
          path={appRoutes.TEACHER_QUIZZ + "/:id/edit"}
          element={<TeacherRoute children={<EditQuizz />} />}
        />

        <Route
          path={
            appRoutes.TEACHER_QUIZZ + "/:id/edit/propositions/:propositionId"
          }
          element={<TeacherRoute children={<EditQuizzProposition />} />}
        />

        <Route
          path={appRoutes.TEACHER_QUIZZ + "/:id/edit/propositions"}
          element={<TeacherRoute children={<PropositionsQuizz />} />}
        />

        <Route
          path={appRoutes.TEACHER_ROOMS + "/:id"}
          element={<TeacherRoute children={<Room />} />}
        />
        <Route
          path={appRoutes.TEACHER_ROOMS + "/:id/challenge"}
          element={<TeacherRoute children={<Room />} />}
        />
        <Route
          path={appRoutes.GETCHAPTERS + "/:id"}
          element={<TeacherRoute children={<GetAllChapters />} />}
        />

        <Route
          path={appRoutes.GETCHAPTER + "/:id"}
          element={<TeacherRoute children={<TeacherFindChapter />} />}
        />

        <Route
          path={appRoutes.FINDQUIZZFORCHAPTER + "/:id"}
          element={<TeacherRoute children={<FindQuizForChapter />} />}
        />

        <Route
          path={appRoutes.FINDEXOFORCHAPTER + "/:id"}
          element={<TeacherRoute children={<FindExoForChapter />} />}
        />

        <Route
          path={appRoutes.COURSESBYLEVELS}
          element={<TeacherRoute children={<CoursesByLevel />} />}
        />

        <Route
          path={appRoutes.TEACHER_CREATE__COURS}
          element={<TeacherRoute children={<TeacherCreateCours />} />}
        />

        <Route
          path={appRoutes.TEACHER_CREATE__QUIZ}
          element={<TeacherRoute children={<TeacherCreateQuiz />} />}
        />

        <Route
          path={appRoutes.TEACHER_CREATE__EXO}
          element={<TeacherRoute children={<CreateExercice />} />}
        />

        <Route
          path={appRoutes.TEACHER_STUDENTS}
          element={<TeacherRoute children={<TeacherStudents />} />}
        />

        <Route
          path={appRoutes.TEACHER_STUDENT_HISTORY + "/:id"}
          element={<TeacherRoute children={<TeacherStudentHistory />} />}
        />

        <Route
          path={appRoutes.PROFILETEACHER + "/:id/:name"}
          element={<ProfileTeacher />}
        />

        <Route path={appRoutes.LOGIN + "/:id"} element={<LoginTeacher />} />
        <Route path={appRoutes.FORBIDDEN} element={<Forbidden />} />
        <Route path={appRoutes.NOT_FOUND} element={<NotFound />} />

        <Route path={appRoutes.FAQ} element={<FAQ />} />
        <Route path={appRoutes.PRIVACY} element={<Privacy />} />
        <Route path={appRoutes.SUCCESS_ACHAT} element={<SuccessAchat />} />
        <Route path={appRoutes.ECHEC_ACHAT} element={<EchecAchat />} />

        <Route path={appRoutes.TEACHER_CONTACT} element={<Contact />} />

        <Route
          path={appRoutes.REGISTER_STUDENT_BY_EMAIL}
          element={<RegisterStudentByMail />}
        />

        <Route
          path={appRoutes.STUDENT_CHAPTERS + "/:id"}
          element={<Chapitre />}
        />
        <Route
          path={appRoutes.STUDENT_LEVELS + "/:id"}
          element={<StudentRoute children={<Level />} />}
        />

        <Route
          path={appRoutes.STUDENT_CALENDAR}
          element={<StudentRoute children={<StudentCalendar />} />}
        />

        <Route
          path={appRoutes.STUDENT_SEARCH_QUIZZ + "/:id"}
          element={<StudentRoute children={<EasyQuiz />} />}
        />

        <Route
          path={appRoutes.STUDENT_QUIZZ}
          element={<StudentRoute children={<StudentQuiz />} />}
        />

        {/* <Route
        path={appRoutes.STUDENT_STATS}
        element={<StudentRoute children={<Stats />} />}
      /> */}
        <Route
          path={appRoutes.STUDENT_SEARCH_BEST_TEACHER}
          element={<SearchTeacher />}
        />

        <Route
          path={appRoutes.STUDENT_FIND_COURSE + "/:id"}
          element={<StudentCours />}
        />

        <Route
          path={appRoutes.STUDENT_EXOS + "/:id"}
          element={<StudentExercices />}
        />

        <Route
          path={appRoutes.STUDENT_EXO + "/:id"}
          element={<StudentExercice />}
        />

        <Route
          path={appRoutes.STUDENT_QUIZZ + "/:id"}
          element={<StudentRoute children={<StudentCoursQuiz />} />}
        />

        <Route
          path={appRoutes.STUDENT_MY_CLASS}
          element={<StudentRoute children={<MyClasses />} />}
        />
        <Route
          path={appRoutes.STUDENT_MY_CLASS + "/:id"}
          element={<StudentRoute children={<RoomStudent />} />}
        />
        <Route
          path={
            appRoutes.STUDENT_MY_CLASS + "/:id" + "/challenge" + "/:idChallenge"
          }
          element={<StudentRoute children={<StudentChallengeQuiz />} />}
        />
        <Route
          path={appRoutes.REGISTER_PARENT}
          element={<RegisterParent />}
        />
        <Route
          path={appRoutes.LOGIN_PARENT}
          element={<LoginParent />}
        />
        <Route
          path={appRoutes.PARENT_DASHBOARD}
          element={<ParentRoute children={<ParentDashboard />} />}
        />
        <Route
          path={appRoutes.STUDENT_HISTORY + "/:id"}
          element={<ParentRoute children={<StudentHistory />} />}
        />
        <Route
          path={appRoutes.STUDENT_STATISTIQUES + "/:id"}
          element={<ParentRoute children={<StudentStatisques />} />}
        />
        <Route
          path={appRoutes.FIND_STUDENT_TEACHER}
          element={<ParentRoute children={<FindStudentTeacher />} />}
        />
        <Route
          path={appRoutes.WALLETS}
          element={<ParentRoute children={<Wallets />} />}
        />
        <Route
          path={appRoutes.PARENT_SUCCESS_ACHAT}
          element={<ParentRoute children={<ParentSuccessAchat />} />}
        />
        <Route
          path={appRoutes.PARENT_ECHEC_ACHAT}
          element={<ParentRoute children={<ParentEchecAchat />} />}
        />

        <Route
          path={appRoutes.REGISTER_ORGANISME}
          element={<RegisterOrganisme />}
        />
        <Route
          path={appRoutes.LOGIN_ORGANISME}
          element={<LoginOrganisme />}
        />
        <Route
          path={appRoutes.ORGANISME_DASHBOARD}
          element={<OrganismeRoute children={<OrganismeDashboard />} />}
        />
        <Route
          path={appRoutes.ORGANISME_STUDENT_HISTORY + "/:id"}
          element={<OrganismeRoute children={<OrganismeStudentHistory />} />}
        />
        <Route
          path={appRoutes.ORGANISM_STUDENT_STATISTIQUES + "/:id"}
          element={<OrganismeRoute children={<OrganismeStudentStatisques />} />}
        />
        <Route
          path={appRoutes.ORGANISM_FIND_STUDENT_TEACHER}
          element={<OrganismeRoute children={<OrganismeFindStudentTeacher />} />}
        />
        <Route
          path={appRoutes.ORGANISM_WALLETS}
          element={<OrganismeRoute children={<OrganismeWallets />} />}
        />
        <Route
          path={appRoutes.ORGANISM_SUCCESS_ACHAT}
          element={<OrganismeRoute children={<OrganismeSuccessAchat />} />}
        />
        <Route
          path={appRoutes.ORGANISM_ECHEC_ACHAT}
          element={<OrganismeRoute children={<OrganismeEchecAchat />} />}
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
