import { Button, Popconfirm, Tooltip } from "antd";
import React, { FC, Fragment, useEffect } from "react";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//IMAGES
import EnglishIcon from "assets/images/students/englishIcon.svg";
import FrenchIcon from "assets/images/students/frenchIcon.svg";
import StartIcon from "assets/images/students/teacherStart.svg";
import TEACHER from "../../../../assets/images/teacher/teacher.svg";

import DiscussionIcon from "assets/images/students/discussionIcon.svg";
import PlanningIcon from "assets/images/students/planning.svg";
import InstaCheck from "../../../../assets/images/app/checkAlert.svg";


//!STYLES
import { FLAGS } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import s from "./assets/cardTeacher.module.css";

import * as appRoutes from "config/routes.config";

import { Link } from "react-router-dom";
import { setIdTeacher, setUserConnect } from "store/auth/authSlice";
import { openPaiementDrawer } from "store/chat/mainSlice";
import { handleClearNiveau, handleClearTopic } from "utils/formats";

import CertificationBadge from "components/certificationBadge";
import {
  setIdReceiver,
  setSelectUserChat,
  toggleAppChat,
} from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import IconTopic from "./iconTopic";

interface CardAdsTeacher {
  ad: any;
  PlanningIsVisble?: boolean;
  isInstaProf?: boolean;
  instaProfData?: any;
  infoTeacherAcceptedReservation?: any;
  currentProfAccepted?: any;
  setCurrentProfAccepted?: any;
  isBtnInstaProf: boolean;
  resetDrawerInstaProf?: () => void;
  handleStudentAcceptTeacher?: any;
  showDrawerSearchInstaProf: () => void;
  handleDisplayTeacher: (id: string) => void;
  onShowDrawerCalendarProf: () => void;
  handleOpenDrwerProfileTeacher: () => void;
  setIsBtnInstaProf: React.Dispatch<React.SetStateAction<boolean>>;
  suggestedTeachers?: boolean;
}

const CardAdsTeacher: FC<CardAdsTeacher> = ({
  ad,
  PlanningIsVisble = true,
  isInstaProf,
  handleStudentAcceptTeacher,
  setCurrentProfAccepted,
  onShowDrawerCalendarProf,
  setIsBtnInstaProf,
  handleDisplayTeacher,
  handleOpenDrwerProfileTeacher,
  showDrawerSearchInstaProf,
  suggestedTeachers,
}) => {
  //!HOOKS
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [findRoom, { isSuccess }] = useFindRoomMutation();

  // const handleDisplay = (id?: any) => {
  //   fetchAds(id);
  //   setVisible(!visible);
  // };

  const closeDrawerPaiement = () => {
    dispatch(openPaiementDrawer());
    // setPopupReservation(false);
  };

  const day = new Date();
  const handleDisplayTeacherMSG = (idTeacher: string) => {
    if (isInstaProf) {
      dispatch(setIsDrawerInstaProf(true));
    }
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: `${isInstaProf ? "instaprof" : ""}`,
      instaprof: `${isInstaProf ? "find" : ""}`,
    };

    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
    console.log("Teacher ad : ", ad);
  }, [isSuccess, ad]);

  return (
    <React.Fragment>
      <div className={`${s.card} ${!isInstaProf && `square`} `}>
        <div className={s.content}>
          {suggestedTeachers && user?.accountManaged !== "" && <div style={{ height: "22px", width: "50%", background: "rgb(0 194 133)", marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.2em", color: "white", borderRadius: "5px", fontWeight: "400" }}>
            Prof recommandé
          </div>}
          <div className={s.card__header}>
            <div className={s.round__user}>
              <img
                src={ad?.teacher?.profile || TEACHER}
              // width={65}
              />
            </div>
            <div style={{ textAlign: "left" }}>
              <Tooltip
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    {ad?.teacher?.firstname + " " + ad?.teacher?.lastname ||
                      "-"}
                  </span>
                }
              >
                <div style={{ display: "flex", flexDirection: "row", color: "#333", fontSize: "1.2em", fontWeight: "600", lineHeight: "1.3", textTransform: "uppercase" }}>
                  {ad?.teacher?.firstname?.split(" ")[0] || "-"}{" "}
                  {ad?.teacher?.lastname?.split(" ")[0] || "-"}
                  {ad?.teacher?.certified && <CertificationBadge />}
                </div>
              </Tooltip>
              <div
                style={{
                  fontFamily: "var(--title-font)",
                  textTransform: "uppercase",
                  lineHeight: "12px",
                  marginTop: "3px",
                }}
              >
                <span>
                  {ad?.prof?.points > 0 &&
                    ad?.prof?.points <= 1000 &&
                    "C'est mon ami"}
                  {ad?.prof?.points > 1000 &&
                    ad?.prof?.points <= 5000 &&
                    "C'est mon grand ami"}
                  {ad?.prof?.points > 5000 &&
                    ad?.prof?.points <= 10000 &&
                    "C'est mon vieil ami"}
                  {ad?.prof?.points > 10000 && "Tu es non meilleur ami"}
                </span>
              </div>
              <div>
                <img
                  src={StartIcon}
                  style={{
                    position: "relative",
                    top: "-2px",
                  }}
                  alt=""
                />{" "}
                <span
                  style={{
                    color: "var(--title-color)",
                    fontSize: "12px",
                  }}
                >
                  <strong
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {ad?.teacher?.rate || 0}
                  </strong>
                  {`(${ad?.teacher?.rateNum || 0} avis)`}{" "}
                </span>
              </div>
              <div>
                <Link
                  to={`${isInstaProf
                    ? "#"
                    : isLoggedIn
                      ? `${appRoutes.PROFILETEACHER}/${ad?.teacher?._id}/${ad?.teacher?.firstname ? ad?.teacher?.firstname : "nom"}.${ad?.teacher?.lastname ? ad?.teacher?.lastname : "complet"}`
                      : "#"
                    }`}
                >
                  <Button
                    type="primary"
                    size="small"
                    block
                    className={s.btn__profil__prof}
                    onClick={() => {
                      if (isLoggedIn) {
                        dispatch(setIdTeacher(ad?._id));
                        isInstaProf && handleOpenDrwerProfileTeacher();
                      } else {
                        dispatch(setUserConnect(true));
                      }
                    }}
                    style={{
                      width: "120px",
                      border: "none",
                      textTransform: "uppercase",
                      fontSize: "11px",
                      borderRadius: "10px",
                    }}
                  >
                    Voir mon PRofil
                  </Button>
                </Link>
              </div>
              <div>
                {!isInstaProf && (
                  <>
                    <span style={{ color: "var(--title-color)" }}>
                      Je parle
                    </span>{" "}
                    {ad?.language?.includes("en") && (
                      <>
                        <img src={EnglishIcon} alt="" />{" "}
                      </>
                    )}
                    {ad?.language?.includes("fr") && (
                      <img src={FrenchIcon} alt="" />
                    )}
                  </>
                )}

                {isInstaProf && (
                  <Fragment>
                    <span style={{ color: "var(--title-color)" }}>
                      Je parle
                    </span>{" "}
                    {ad?.prof?.langues?.includes("en") && (
                      <>
                        <img src={EnglishIcon} alt="" />{" "}
                      </>
                    )}
                    {ad?.prof?.langues?.includes("fr") && (
                      <img src={FrenchIcon} alt="" />
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div className={s.tarif}>
            <span>Mes cours à partir de</span>{" "}
            {isInstaProf ? ad?.studentCost : ad?.minPrice}€/séance
          </div>

          <div className={s.__matiers}>
            {handleClearTopic(ad?.topic)?.map((item: any) => (
              <div className={s.__tag__topic}>
                <IconTopic data={item} />
                {item?.title?.substring(0, 4)}
              </div>
            ))}
          </div>

          <div className={s.levelGroup}>
            {handleClearNiveau(ad?.teacher?.nivMat)?.map((niv: any) => {
              return (
                (niv?.niveau?.levelGroup?.includes("Primaire") && (
                  <>
                    <span>Primaire </span> <span>- </span>
                  </>
                )) ||
                (niv?.niveau?.levelGroup?.includes("Collège") && (
                  <>
                    <span>Collège </span>
                    <span>- </span>
                  </>
                )) ||
                (niv?.niveau?.levelGroup?.includes("Lycée") && (
                  <>
                    <span>Lycée </span>
                    <span>- </span>
                  </>
                )) ||
                (niv?.niveau?.levelGroup?.includes("Prépa") && (
                  <>
                    <span>Prépa </span>
                    <span>- </span>
                  </>
                ))
              );
            })}
            {ad?.prof?.nivMat?.length <= 0 && (
              <>
                <span>Non renseigné</span>
                <span>-</span>
              </>
            )}
          </div>

          <div className={s.__description}>
            <Latex>
              {ad?.teacher?.career
                ? ad?.teacher?.career?.substring(0, 300)?.concat("...")
                : "Aucune description disponible"}
            </Latex>
          </div>

          <div className={s.__row}>
            {PlanningIsVisble && (
              <button onClick={onShowDrawerCalendarProf}>
                <img
                  src={PlanningIcon}
                  alt=""
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "5px",
                  }}
                />
                Mon Planning
              </button>
            )}

            {isInstaProf && (
              <Popconfirm
                placement="top"
                title={"Voulez-vous accepter ce prof ?"}
                onConfirm={() => handleStudentAcceptTeacher(ad)}
                okText="Yes"
                cancelText="No"
              >
                <button onClick={() => setCurrentProfAccepted(ad)}>
                  Accepter
                </button>
              </Popconfirm>
            )}

            {!isInstaProf && (
              <button
                onClick={() => {
                  // setCurrentProfAccepted(ad);
                  setIsBtnInstaProf(true);
                  showDrawerSearchInstaProf();
                }}
              >
                <img
                  src={InstaCheck}
                  alt=""
                  style={{ height: "20px", marginRight: "5px" }}
                />
                InstaProf
              </button>
            )}

            {user?._id && (
              <button
                onClick={() => {
                  isInstaProf
                    ? handleDisplayTeacher(ad?.teacher?._id)
                    : handleDisplayTeacherMSG(ad?.teacher?._id);
                }}
              >
                <img
                  src={DiscussionIcon}
                  alt=""
                  style={{
                    width: "18px",
                    height: "18px",
                    position: "relative",
                    top: "-1px",
                    // marginRight: "5px",
                  }}
                />{" "}
                {/* Viens discuter */}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardAdsTeacher;
