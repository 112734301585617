import { Avatar, Col, Divider, Row, Skeleton, Space } from "antd";
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import IcoonCalendar from "assets/images/icons/calendar.svg";
import IconRoom from "assets/images/icons/classes.svg";
import { StatusCode } from "common/enums";
import HelpPopover from "components/help";
import H2 from "components/libs/subtitle";
import Matiers from "components/matiers";
import * as appRoutes from "config/routes.config";
import moment from "moment";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import s from "./style.module.css";

interface CardRoomProps {
  challenge: boolean;
  course?: object | any;
}

const CardRoom: FC<CardRoomProps> = ({ challenge, course }) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const redirectHandler = () => {
    if (auth?.user?._id === course?.teacher?._id) {
      return navigate(`${appRoutes.TEACHER_ROOMS}/${course?._id}`);
    } else {
      return navigate(`${appRoutes.STUDENT_MY_CLASS}/${course?._id}`);
    }
  };

  return (
    <div className={s.card} onClick={redirectHandler} style={{ cursor: "pointer" }}>
      <Space>
        {course?.title ||
          `Code de la classe : ${course?.accessCode?.split("-")[0].toUpperCase() ||
          "Nom indisponible"
          }`}
        <Matiers
          className={s.tag}
          classNameIcon={s.tagIcon}
          cours={course?.course?.title.slice(0, 4) || "null"}
          level={course?.level?.title || "null"}
        />
      </Space>
      {challenge ?
        <div className={s.challenge}>
          <div className={s.round} />
          Challenge en cours
        </div> :
        <></>
      }
    </div>
  );
};

const TeacherEvents = () => {
  const auth = useSelector((state) => state.auth);

  const [coursesLoading, setCoursesLoading] = useState(false);
  const [coursesError, setCoursesError] = useState("");
  const [rooms, setRooms] = useState<any>([]);
  const { data, isLoading, isError, refetch } = useGetHistoriqueQuery(
    auth?.user?._id
  );

  const showLink = async (
    urlProf: any | undefined,
    urlStud: any | undefined,
    idProf: any | undefined
  ) => {
    //check if Prof
    if (idProf === auth?.user?._id) {
      window.open(urlProf, '_blank');
    } else {
      window.open(urlStud, '_blank');
    }
  };

  const fetchCourses = useCallback(async (id: string | undefined) => {
    try {
      setCoursesLoading(true);
      let response = await ROOMS_SERVICES.findByUser(auth.user._id);
      if (response?.statusCode === StatusCode.OK) {
        //loop into classes and check if have challenge 
        for (let i = 0; i < response.data.length; i++) {
          try {
            let challengeFound = await CHALLENGES_SERVICES.findByIdClasse(response.data[i]._id);
            if (challengeFound?.statusCode === StatusCode.OK) {
              response.data[i] = { ...response.data[i], hasChallenge: true };
            }
          } catch (innerError) {
            // Handle any exceptions thrown within the loop, if necessary
            console.error("Error in loop iteration:", innerError);
            response.data[i] = { ...response.data[i], hasChallenge: false };
          }
        }
        console.log('classes with challenges ', response.data)
        setRooms(response.data);
      } else {
        setCoursesError(response?.message || "Erreur serveur");
      }
    } catch (error: any) {
      console.log(error);
      setCoursesError(error?.message || "error");
    } finally {
      setCoursesLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses(auth.user?._id);
  }, [auth.user?._id, fetchCourses]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className={s.teacherEvents}>
      <div className={s.top}>
        <Space align="start">
          <img src={IcoonCalendar} width={35} />
          <div>
            <H2>Mes prochains cours</H2>
          </div>
          <HelpPopover id={"accueil_prochains_cours"} />
        </Space>
        <div className={s.scheduled__course}>
          {isLoading ? (
            <>
              <Skeleton />
            </>
          ) : (
            <Fragment>
              {data?.data?.length > 0 ? (
                <>
                  {data?.data?.map((cren: any) => (
                    <div className={s.card} onClick={() => { showLink(cren?.profLink, cren?.studLink, cren?.profId?._id) }} style={{ cursor: "pointer" }}>
                      <div>
                        <span>{moment(cren?.day).format("LL")} à {cren.range[0]}</span>
                      </div>
                      <div>
                        <Row
                          justify="start"
                          align="middle"
                          gutter={24}
                        >
                          <Col>
                            <Avatar.Group
                              maxCount={2}
                              maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                            >
                              <Avatar
                                size={38}
                                style={{ backgroundColor: "#7fb1b2" }}
                                src={cren?.profId?._id === auth?.user?._id ? cren?.studId?.profile : cren?.profId?.profile}
                              />
                            </Avatar.Group>
                          </Col>
                          <Col>
                            <H2>
                              {cren?.profId?._id === auth?.user?._id ? cren?.studId?.firstname : cren?.profId?.firstname}{" "}
                              {cren?.profId?._id === auth?.user?._id ? cren?.studId?.lastnamelastname : cren?.profId?.lastname}
                            </H2>
                          </Col>
                          <Col>
                            <div className={s.matiere}>
                              <Matiers
                                style={{ margin: 0 }}
                                cours={cren?.topic?.title}
                                level={cren?.level?.title}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <span>Aucune réservation</span>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <Divider />

      <div className={s.bottom}>
        <Space align="start">
          <img src={IconRoom} width={35} />
          <div>
            <H2>Mes classes</H2>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
            <HelpPopover id={"accueil_mes_classes"} />
          </div>
        </Space>

        <div>
          {!coursesLoading &&
            rooms?.map((course: any) => (
              <Fragment>
                <CardRoom challenge={course.hasChallenge} course={course} />
              </Fragment>
            ))}

          {rooms.length === 0 && (
            <div className={s.scheduled__course}>
              <span>Aucune classe</span>
            </div>
          )}

          {/* <CardRoom challenge /> */}
        </div>
      </div>
    </div>
  );
};

export default TeacherEvents;
