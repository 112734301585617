import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL } from "./faq.routes";

export const findAllFaq = async (type: string) => {
  try {
    const response = await axios.get(API_BASE_URL(type));
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchHelp = async (
  emplacement: string,
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/help-assistant/fetch/assistant/emplacement/${emplacement}`);
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};
