import { useEffect } from "react";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Stylo from "components/stylo";

//!STYLE
import s from "../assets/success.module.css";

import { useDispatch } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import {
  setShowDrawerPaiementOneMoreTimeHandler
} from "store/auth/authSlice";
import AnimatedPage from "utils/animatedPage";

const Failing = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowDrawerPaiementOneMoreTimeHandler());
  }, []);

  return (
    <AnimatedPage>
      <Stylo />

      <Breadcrumbs />
      <AppScreen>
        <div className={s.container}>
          <div className={s.card}>
            <div
              style={{
                borderRadius: "200px",
                height: "200px",
                width: "200px",
                background: " red",
                margin: "0 auto",
              }}
            >
              <i className={s.italic} style={{ color: "white" }}>
                X
              </i>
            </div>
            <h1 className={s.title} style={{ color: "red" }}>
              Echec
            </h1>
            <p className={s.paragraph}>
              {/* votre demande d'achat n'a pas été prise en compte suite à une
              erreur inattendue
              <br />
              Mon ami Albert vous remercie pour votre compréhension. <br />
              On vous invite à refaire l'achat encore une fois,
              <Link to="/"> pour cela cliquer ici</Link> */}
              Paiement échoué , réessayer plus tard
              <br />
              Ou contactez-nous par email ( contact@monamialbert.com )
            </p>
          </div>
        </div>
      </AppScreen>
    </AnimatedPage>
  );
};

export default Failing;
