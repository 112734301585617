import { PlusOutlined } from "@ant-design/icons";
import { Button, Row, Skeleton, Typography } from "antd";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import Col from "antd/es/grid/col";
import axios from "axios";
import AppResult from "components/appResults";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import useWebhook from "hooks/useWebHook";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawer, displayDrawerAdsSpecific } from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import DrawerAdsCreneauSpecific from "../components/availabilitySpecific/drawer";
import st from "./calendar.module.css";
import CardCreneau from "./card";
import TeacherCalendarDetails from "./drawerDetails";
import s from "./style.module.css";


type Props = {
  currentTeacher: any;
};

interface Niche {
  date: string;
  level: {
    _id: string;
    title: string;
    levelGroup: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  topic: {
    _id: string;
    title: string;
    level: string;
    status: string;
    position: number;
    language: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    price: string;
  };
  timeRanges: string[];
  idCreneau: string;
  reservations: any[];
  status: any[];
  id: number;
  day: string;
}

interface Obj {
  day: string;
  niches: Niche[];
}

const { Text } = Typography;

export const handleRemoveDoublonNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.levelGroup === arr?.levelGroup &&
          t?.levelGroup?._id === arr?.levelGroup?._id
      )
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
  ],
};

const MainTeacherCalendar: FC<Props> = ({ currentTeacher }) => {

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sendWebhook] = useWebhook();
  const [creneau, setCreneau] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [allSuccessRes, setAllSuccessRes] = useState<any>([]);
  const [allCreneaux, setAllCreneaux] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const [drawerRes, setDrawerRes] = useState<boolean>(false);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const [confirmReservations, setConfirmRes] = useState<any[]>([]);

  const { isCalendarUpdated } = useSelector((state) => state.app);

  const generateNiches = useCallback(
    (data: any) => {
      const niches = [];
      if (Array.isArray(data?.niches) && data?.niches?.length > 0) {
        const topics = Array.isArray(data?.niches[0]?.topic)
          ? data?.niches[0]?.topic
          : [];
        const levels = Array.isArray(data?.niches[0]?.level)
          ? data?.niches[0]?.level
          : [];
        const topicsLength = topics?.length;
        const levelsLength = levels?.length;

        for (let i = 0; i < topicsLength; i++) {
          for (let j = 0; j < levelsLength; j++) {
            const filteredTopics = data?.niches?.map((n: any) =>
              Array.isArray(n.topic) && Array.isArray(n?.level)
                ? n?.topic.filter(
                  (t: any) =>
                    t?.level === n?.level[j]._id && t?.position === i + 1
                )
                : []
            );

            const foundTopic = filteredTopics?.find((n: any) => n?.length > 0);

            if (foundTopic) {
              const niche = {
                date: data.niches[0].date,
                level: data.niches[0].level[j],
                topic: foundTopic[j],
                timeRanges: data.niches[0].timeRanges[j],
                idCreneau: data.niches[0].idCreneau,
                reservations:
                  data.niches[0] && data.niches[0].reservations
                    ? data.niches[0].reservations[j] || []
                    : [],
                status: data.niches[0].status[j] || [],
                annonce: data.annonce,
                id: j + 1,
                creneauTypes: data.niches[0].creneauTypes[j] || [],
                day: data.day,
              };
              niches.push(niche);
            }
          }
        }
      }
      const output = { day: data.day, niches: niches };
      setCreneau(output);
      return output;
    },
    [creneau]
  );

  const fetchCalender = useCallback(
    async (idTeacher: string) => {
      setLoading(true);
      if (user?._id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/fetch/ReelCalender/prof/instance/${idTeacher}`
          );
          setAllData(response.data?.data || []);

          if (allCreneaux?.length <= 0) {
            const result = response.data?.data
              .filter((data: any) => data?.niches?.length > 0)
              .map((niche: any) => generateNiches(niche));

            setAllCreneaux(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          dispatch(setIsCalendarUpdated(false));
        }
      }
    },
    [user?._id]
  );

  useEffect(() => {
    fetchCalender(user?._id);
  }, [currentTeacher]);

  useEffect(() => {
    fetchCalender(user?._id);
  }, [isCalendarUpdated]);

  useEffect(() => {
    const filterReservations = (allCreneaux: any[]): any[] => {
      const reservations: any[] = allCreneaux.filter((creneau: any) => {
        return creneau.niches.some((niche: any) => {
          return niche.reservations && niche.reservations._id;
        });
      });

      const successReservations: any[] = reservations.reduce(
        (acc: any[], creneau: any) => {
          const niche = creneau.niches[0];
          if (niche.reservations) {
            acc.push({
              ...niche.reservations,
              topic: niche.topic,
            });
          }
          return acc;
        },
        []
      );

      return successReservations;
    };

    const myReservations: any[] = filterReservations(allCreneaux);

    setAllSuccessRes((res: any) => [...res, myReservations]);
  }, [allCreneaux]);

  return (
    <div className={st.calendar}>
      <div className={st.header}>
        <div className={st.left}>
          <H2 className={st.subtitle}>Mon calendrier</H2>
        </div>
        <div style={{ position: "relative", top: "15px", marginLeft: "10px" }}><HelpPopover id={"dashboard_mon_calendrier"} /></div>

        <div className={st.center} />

        <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
          <div style={{ position: "relative", top: "-35px", left: "30%" }}><HelpPopover id={"calendrier_ajouter_un_creneau"} /></div>
          <div style={{ position: "relative", top: "-35px", left: "70%" }}><HelpPopover id={"calendrier_gestion_et_ajout_disponibilité"} /></div>
          <AppButton
            icon={<PlusOutlined />}
            onClick={() => dispatch(displayDrawerAdsSpecific(true))}
            style={{ marginRight: "10px", padding: "0px" }}
          >
            Ajouter un créneau
          </AppButton>

          <AppButton
            icon={<PlusOutlined />}
            onClick={() => dispatch(displayDrawer(true))}
          >
            Gestion et ajout des disponibilités
          </AppButton>
        </div>
      </div>

      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {allData?.length > 0 ? (
            <div className={s.calendar}>
              <div className={s.weekly}>
                <div style={{ width: "5%" }}>
                  <Button
                    onClick={() => {
                      setConfirmRes([]);
                      setIsAllData(true);
                    }}
                    style={{ backgroundColor: isAllData ? "var(--first-color)" : "transparent", color: isAllData ? "#fff" : "#333", borderRadius: "5px" }}
                  >
                    Afficher tout
                  </Button>
                </div>
                <div style={{ width: "82%", marginRight: "20px" }}>
                  <Slider {...settings}>
                    {allData?.map((item: any, index: number) => (
                      <div
                        key={index}
                        ref={(el) => (dateRef.current[index] = el)}
                        onClick={() => {
                          generateNiches(item);
                          setConfirmRes([]);
                          setIndex(index);
                          setIsAllData(false);
                        }}
                        className={`${s.date} ${creneau?.day === item?.day && s.active
                          }`}
                      >
                        <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              {isAllData ? (
                allCreneaux?.map((cren: any, index: number) => {
                  console.log(cren?.niches);
                  return (
                    <div key={index}>
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ marginBottom: "2em" }}
                      >
                        <Col>
                          <H2>{moment(cren?.day).format('dddd D MMMM YYYY')}</H2>
                        </Col>
                      </Row>

                      {cren?.niches?.map((c: any, index: number) => {
                        return (
                          /* c?.status !== "deleted" && ( */<CardCreneau
                            isAllReservation={false}
                            key={index}
                            cren={c}
                            index={index}
                            currentTeacher={currentTeacher}
                            confirmReservations={confirmReservations}
                            setConfirmRes={setConfirmRes}
                          />/* ) */
                        );
                      })}
                    </div>
                  )
                })
              ) : (
                <div>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <H2>{moment(creneau?.day).format('dddd D MMMM YYYY')}</H2>
                    </Col>
                  </Row>

                  {creneau?.niches?.length <= 0 ? (
                    <AppResult
                      status="info"
                      title={`Vous n'avez aucune disponibilité pour le ${moment(
                        creneau?.day
                      ).format("LL")}`}
                    />
                  ) : (
                    <Fragment>
                      {creneau?.niches?.map((cren: any, index: number) => {
                        return (
                          /* cren?.status !== "deleted" ? ( */<CardCreneau
                            key={index}
                            cren={cren}
                            index={index}
                            isAllReservation={false}
                            currentTeacher={currentTeacher}
                            confirmReservations={confirmReservations}
                            setConfirmRes={setConfirmRes}
                          />/* ) : creneau?.niches?.length < 2 ? (
                            <AppResult
                              status="info"
                              title={`Vous n'avez aucune disponibilité pour le ${moment(
                                creneau?.day
                              ).format("LL")}`}
                            />
                          ) : (<></>) */
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              )}
            </div>
          ) : (
            <AppResult
              status={"info"}
              title="Vous n'avez aucune disponibilité"
            />
          )}
        </Fragment>
      )}

      <TeacherCalendarDetails
        currentTeacher={currentTeacher}
        drawerRes={drawerRes}
        allSuccessRes={allSuccessRes}
        setDrawerRes={setDrawerRes}
      />
      <DrawerAdsCreneauSpecific />
    </div>
  );
};

export default MainTeacherCalendar;
